import { MutationFunction, useMutation } from '@tanstack/react-query'
import { FormEvent, useCallback } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

interface MutationCallbackOptions {
  successKey?: string
  errorKey?: string
  tNamespace?: string | string[]
}

export const useApiCallback = (
  loader: MutationFunction<unknown, unknown>,
  data: unknown,
  {
    successKey,
    errorKey,
    tNamespace = 'dashboard',
  }: MutationCallbackOptions = {}
) => {
  const { t } = useTranslation(tNamespace)
  const { mutate } = useMutation(loader)

  return useCallback(async () => {
    mutate(data, {
      onSuccess: () => {
        if (successKey) {
          toast.success(t(successKey))
        }
      },
      onError: (error: unknown) => {
        if (errorKey) {
          toast.error(
            t(errorKey, {
              error: (error as Error).toString(),
            })
          )
        }
      },
    })
  }, [data])
}

export const useFormCallback = (
  loader: MutationFunction<unknown, unknown>,
  data: unknown,
  {
    successKey,
    errorKey,
    tNamespace = 'dashboard',
  }: MutationCallbackOptions = {}
) => {
  const { t } = useTranslation(tNamespace)
  const saveMutation = useMutation(loader)

  return useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      saveMutation.mutate(data, {
        onSuccess: () => {
          if (successKey) {
            toast.success(t(successKey))
          }
        },
        onError: (error: unknown) => {
          if (errorKey) {
            toast.error(
              t(errorKey, {
                error: (error as Error).toString(),
              })
            )
          }
        },
      })
    },
    [data]
  )
}
