import { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

const FormValue = ({ children }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className="mt-2">
      <span className="block w-full text-gray-900 sm:text-sm sm:leading-6">
        {children}
      </span>
    </div>
  )
}

const FormLabel = ({
  children,
  ...props
}: ComponentPropsWithoutRef<'label'>) => {
  return (
    <label
      className="block text-sm font-medium leading-6 text-gray-900"
      {...props}
    >
      {children}
    </label>
  )
}

// A single field in the form
const FormField = ({
  children,
  classNames,
}: ComponentPropsWithoutRef<'div'> & {
  classNames?: string
}) => {
  return <div className={twMerge('sm:col-span-3', classNames)}>{children}</div>
}

// A section contains a 6-col grid of fields
const FormSection = ({
  children,
  classNames,
}: ComponentPropsWithoutRef<'div'> & {
  classNames?: string
}) => {
  return (
    <div className="border-b border-gray-900/10 pb-12">
      <div
        className={twMerge(
          'mt-1 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6',
          classNames
        )}
      >
        {children}
      </div>
    </div>
  )
}

// The overall form component spacing out sections
const Form = ({ children }: ComponentPropsWithoutRef<'form'>) => {
  return (
    <form>
      <div className="space-y-12">{children}</div>
    </form>
  )
}

export { Form, FormField, FormLabel, FormSection, FormValue }
