import moment from 'moment'

export const formatInterval = (
  year: number,
  interval: number,
  byInterval: 'month' | 'quarter',
  { locale = navigator.language }: { locale?: string } = {}
) => {
  const m = moment.utc()

  m.locale(locale)
  m.year(year)

  if (byInterval === 'month') {
    m.month(interval - 1) // month() indexes from 0
    return `${m.format('MMM')} ’${m.format('YY')}`
  } else if (byInterval === 'quarter') {
    m.quarter(interval)
    return `Q${m.format('Q')} ’${m.format('YY')}`
  }

  return `${year}-${interval}`
}
