import { useQuery } from '@tanstack/react-query'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Checked from '../components/Checked'
import Page from '../components/Page/LegacyPage'
import Spinner from '../components/Spinner'
import Grid from '../components/Users/Grid'
import Button from '../components/core/Button/Button'
import { getUsers } from '../loaders/user'

export default function Users() {
  const gridRef = useRef(null)
  const { t } = useTranslation(['dashboard'])

  const { data: users, isLoading } = useQuery(['users'], getUsers)

  if (isLoading) {
    return null
  }

  return (
    <Checked right="user:admin">
      <Page>
        <Page.Header
          actions={
            <>
              <Button
                as={Link}
                to="/admin/users/new"
                variant="primary"
                className="ml-2"
              >
                {t('users.createUser')}
              </Button>
            </>
          }
        >
          {t('users.title')}
        </Page.Header>
        <Page.Section id="grid">
          {isLoading ? (
            <div className="flex justify-center">
              <Spinner />
            </div>
          ) : (
            <Grid users={users} ref={gridRef} />
          )}
        </Page.Section>
      </Page>
    </Checked>
  )
}
