import httpClient from '../utils/httpClient'

export const getOrganization = async () => {
  const response = await httpClient.get(`/organization`)

  return response.data
}

export const updateOrganization = async ({ name }) => {
  const response = await httpClient.patch(`/organization`, {
    name,
  })

  return response.data
}
