import qs from 'query-string'

import httpClient from '../utils/httpClient'

export const getBuildings = async () => {
  const response = await httpClient.get(`/building/load`)

  return response.data
}

export const updateBuilding = (data) => {
  return httpClient.patch(`/building/${data.buildingId}/update`, data)
}

export const createBuilding = (data) => {
  return httpClient.post(`/building/create`, data)
}

export const bulkDelete = (ids) => {
  return Promise.all(
    ids.map((id) => httpClient.delete(`/building/${id}/delete`))
  )
}

export const uploadBuildingsFile = (file) => {
  const formData = new FormData()
  formData.append('file', file)

  return httpClient.post(`/building/upload`, formData)
}

export const getPortfolioAttributeDistribution = async (
  portfolioId,
  { attribute } = {}
) => {
  const url = qs.stringifyUrl({
    url: `/building/portfolio/${portfolioId}/attribute_distribution`,
    query: {
      attribute,
    },
  })

  const response = await httpClient.get(url)

  return response.data
}
