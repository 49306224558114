import classnames from 'classnames'
import React, { ComponentPropsWithoutRef } from 'react'

import { useFieldContext } from './InputField'

interface InputProps extends ComponentPropsWithoutRef<'input'> {
  id?: string
  type: 'email' | 'password' | 'text' | 'number'
}

const Input = ({ id, type = 'text', ...props }: InputProps) => {
  const context = useFieldContext() as { id: string }

  return (
    <input
      id={id || context.id}
      type={type}
      className={classnames(
        'block w-full appearance-none rounded-md border',
        'border-gray-300 placeholder-gray-400 shadow-sm',
        'focus:border-ps-500 focus:outline-none focus:ring-ps-500',
        'px-3 py-2 sm:text-sm'
      )}
      {...props}
    />
  )
}

export default Input
