import { useMutation, useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import Card from '../components/Card/Card'
import Checked from '../components/Checked'
import Page from '../components/Page/LegacyPage'
import Button from '../components/core/Button/Button'
import Input from '../components/core/Input/Input'
import InputField from '../components/core/Input/InputField'
import Label from '../components/core/Input/Label'
import { getOrganization, updateOrganization } from '../loaders/organizations'

export default function Apps() {
  const { t } = useTranslation(['dashboard'])

  const [state, setState] = useState({
    name: '',
  })

  const {
    data: organization,
    status,
    isLoading,
  } = useQuery(['organization'], getOrganization)

  const saveMutation = useMutation({
    mutationFn: updateOrganization,
  })

  useEffect(() => {
    if (status === 'success') {
      setState({
        name: organization.name,
      })
    }
  }, [status, organization])

  const onSubmit = useCallback(async (event) => {
    event.preventDefault()
    saveMutation.mutate(
      {
        name: state.name,
      },
      {
        onSuccess: () => {
          toast.success(t('organization.notification.success'))
        },
        onError: (error) => {
          toast.error(
            t('organization.notification.error', {
              error: error.toString(),
            })
          )
        },
      }
    )
  })

  if (isLoading) {
    return null
  }

  return (
    <Checked right="org:admin">
      <Page>
        <Page.Header>{t('organization.title')}</Page.Header>
        <Page.Section>
          <Card>
            <form
              onSubmit={onSubmit}
              className="p-6 space-y-8 divide-y divide-gray-200"
            >
              <div className="space-y-8 divide-y divide-gray-200">
                <div>
                  <InputField id="organization-name-field">
                    <Label className="mb-2">
                      {t(`organization.field.name`)}
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      value={state.name}
                      onChange={(event) =>
                        setState((prev) => ({
                          ...prev,
                          name: event.target.value,
                        }))
                      }
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      {t(`organization.field.description`)}
                    </p>
                  </InputField>
                </div>
              </div>

              <div className="pt-5">
                <div className="flex justify-end">
                  <Button className="ml-3" type="submit" variant="primary">
                    {t('form.save', { ns: 'translation' })}
                  </Button>
                </div>
              </div>
            </form>
          </Card>
        </Page.Section>
      </Page>
    </Checked>
  )
}
