import { getGridValueFormatter } from '../../../utils/number'

export default (numberOptions) => ({
  headerName: 'Underwriting',
  field: 'underwriting',
  width: 150,
  resizable: true,
  cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
  valueFormatter: getGridValueFormatter('underwriting', numberOptions),
})
