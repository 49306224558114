import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Card from '../components/Card/Card'
import Checked from '../components/Checked'
import { Content, Header, Page, Section, Title } from '../components/Page/Page'
import Button from '../components/core/Button/Button'
import Label from '../components/core/Input/Label'
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '../components/core/Listbox'
import { useFormCallback } from '../hooks/api'
import useSettings from '../hooks/useSettings'
import { updateSettings } from '../loaders/user'

const NUMBER_LOCALES = ['en-US'] as const

type NumberLocale = (typeof NUMBER_LOCALES)[number]

interface Settings {
  numberLocale?: NumberLocale | null
}

interface NumberLocaleFieldProps {
  settings: Settings
  onChange: (numberLocale: NumberLocale | null) => void
}

const NumberLocaleField = ({ settings, onChange }: NumberLocaleFieldProps) => {
  const { t } = useTranslation(['dashboard'])

  return (
    <div>
      <Listbox value={settings.numberLocale} onChange={onChange}>
        {({ open }) => (
          <>
            <Label className="mb-2">
              {t(`profile.settings.field.numberLocale.title`)}
            </Label>
            <div className="relative">
              <ListboxButton>
                {t(
                  `profile.settings.numberLocales.${settings.numberLocale || 'system'}`
                )}
              </ListboxButton>
              <ListboxOptions isOpen={open}>
                {[null, ...NUMBER_LOCALES].map((locale) => (
                  <ListboxOption key={locale} value={locale}>
                    {t(`profile.settings.numberLocales.${locale || 'system'}`)}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </div>
          </>
        )}
      </Listbox>
      <p className="mt-2 text-sm text-gray-500">
        {t(`profile.settings.field.numberLocale.description`)}
      </p>
    </div>
  )
}

const Profile = () => {
  const { t } = useTranslation(['dashboard'])
  const [settings, setSettings] = useState<Settings>({
    numberLocale: null,
  })

  const { isLoading, settings: loadedSettings } = useSettings()
  useEffect(() => {
    if (loadedSettings) {
      setSettings(loadedSettings)
    }
  }, [loadedSettings])

  const onSubmit = useFormCallback(updateSettings, settings, {
    successKey: 'profile.notification.success',
    errorKey: 'profile.notification.error',
  })

  if (isLoading) {
    return null
  }

  return (
    <Checked right="user:self:write">
      <Page>
        <Header>
          <span>{t('profile.title')}</span>
        </Header>
        <Section id="settings">
          <Title>{t('profile.settings.title')}</Title>
          <Content>
            <Card>
              <form
                onSubmit={onSubmit}
                className="p-6 space-y-8 divide-y divide-gray-200"
              >
                <div className="space-y-8 divide-y divide-gray-200">
                  <NumberLocaleField
                    settings={settings}
                    onChange={(value) =>
                      setSettings((prev) => ({
                        ...prev,
                        numberLocale: value,
                      }))
                    }
                  />
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <Button className="ml-3" type="submit" variant="primary">
                      {t('form.save', { ns: 'translation' })}
                    </Button>
                  </div>
                </div>
              </form>
            </Card>
          </Content>
        </Section>
      </Page>
    </Checked>
  )
}

export default Profile
