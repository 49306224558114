import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { NavLink, useMatch } from 'react-router-dom'

import useAuth from '../../../hooks/useAuth'
import { NavGroupItem, NavItem } from '../navigation'

interface SidebarItemProps {
  item: NavItem
}

export const SidebarItem = ({ item }: SidebarItemProps) => {
  const current = useMatch(item.href)
  const auth = useAuth()
  const { t } = useTranslation()

  if (item.right && !auth.rights.includes(item.right)) {
    return null
  }

  return (
    <NavLink
      to={item.href}
      className={classNames(
        current
          ? 'bg-gray-50 text-brand-700'
          : 'hover:bg-gray-50 text-gray-700',
        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
      )}
    >
      {item.icon && (
        <item.icon
          aria-hidden="true"
          className="h-6 w-6 shrink-0 text-gray-400"
        />
      )}
      {t(item.label)}
    </NavLink>
  )
}

interface SidebarGroupSubItemProps {
  item: NavItem
}

export const SidebarGroupSubItem = ({ item }: SidebarGroupSubItemProps) => {
  const current = useMatch(item.href)
  const auth = useAuth()
  const { t } = useTranslation()

  if (item.right && !auth.rights.includes(item.right)) {
    return null
  }

  return (
    <li key={item.label}>
      <NavLink
        to={item.href}
        className={classNames(
          current
            ? 'bg-gray-50 text-brand-700'
            : 'hover:bg-gray-50 text-gray-700',
          'block rounded-md py-2 pl-9 pr-2 text-sm leading-6'
        )}
      >
        {t(item.label)}
      </NavLink>
    </li>
  )
}

interface SidebarGroupItemProps {
  item: NavGroupItem
}

export const SidebarGroupItem = ({ item }: SidebarGroupItemProps) => {
  const current = useMatch(item.scope)
  const auth = useAuth()
  const { t } = useTranslation()

  // only display children with right to access
  const children = item.children.filter(
    (child) => !child.right || auth.rights.includes(child.right)
  )

  if (children.length === 0) {
    return null
  }

  return (
    <Disclosure as="div">
      <DisclosureButton
        className={classNames(
          current
            ? 'bg-gray-50 text-brand-700'
            : 'hover:bg-gray-50 text-gray-700',
          'group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6'
        )}
      >
        {item.icon && (
          <item.icon
            aria-hidden="true"
            className="h-6 w-6 shrink-0 text-gray-400"
          />
        )}
        {t(item.label)}
        <ChevronRightIcon
          aria-hidden="true"
          className="ml-auto h-5 w-5 shrink-0 text-gray-400 group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
        />
      </DisclosureButton>
      <DisclosurePanel as="ul" className="mt-1 px-2">
        {item.children.map((subItem) => (
          <SidebarGroupSubItem key={subItem.label} item={subItem} />
        ))}
      </DisclosurePanel>
    </Disclosure>
  )
}
