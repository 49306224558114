import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useMemo } from 'react'
import useResizeObserver from 'use-resize-observer'

import AgGridReact from '../Grid/Grid'
import Page from '../Page/LegacyPage'
import { Actions, Email, FirstName, LastName, Role } from './columns'

const Grid = forwardRef(({ users }, gridRef) => {
  useResizeObserver({
    ref: document.body,
    onResize: () => {
      if (gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit({
          defaultMinWidth: 100,
        })
      }
    },
  })

  const onFirstDataRendered = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.sizeColumnsToFit({
        defaultMinWidth: 100,
      })
    }
  }, [])

  const gridOptions = useMemo(() => ({
    columnDefs: [FirstName, LastName, Email, Role, Actions],
  }))

  return (
    <>
      <Page.Section.Content className="ag-theme-alpine">
        <AgGridReact
          domLayout="autoHeight"
          onFirstDataRendered={onFirstDataRendered}
          modules={[ClientSideRowModelModule]}
          gridOptions={gridOptions}
          rowData={users}
          ref={gridRef}
        />
      </Page.Section.Content>
    </>
  )
})

Grid.propTypes = {
  users: PropTypes.array.isRequired,
}

Grid.displayName = 'Grid'

export default Grid
