import { getGridValueFormatter } from '../../../utils/number'

export default (numberOptions) => ({
  headerName: 'Budget',
  field: 'budget',
  width: 150,
  resizable: true,
  cellClass: ['c-grid-cell', 'c-grid-cell--numeric'],
  valueFormatter: getGridValueFormatter('budget', numberOptions),
})
