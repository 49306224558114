import _ from 'lodash'

interface Options {
  signDisplay?: 'auto' | 'always' | 'exceptZero' | 'negative' | 'never'
  currency?: string
  decimals?: number
  locale?: string
}

export const format = (
  number: number | null,
  { signDisplay, currency, decimals = 2, locale }: Options = {}
) => {
  if (number == null) {
    return number
  }

  if (!locale) {
    locale = navigator.language
  }

  let currencyOptions = {}
  if (currency) {
    currencyOptions = {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency,
    }
  }

  return new Intl.NumberFormat(locale, {
    signDisplay,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    trailingZeroDisplay: 'stripIfInteger',
    ...currencyOptions,
  }).format(number)
}

export const getGridValueFormatter = (field: string, options: Options = {}) => {
  return function (params: Record<string, unknown>) {
    const value = _.get(params, ['data', field])

    return format(value, options)
  }
}
