import { useMutation, useQuery } from '@tanstack/react-query'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Grid from '../components/Buildings/Grid'
import Checked from '../components/Checked'
import Page from '../components/Page/LegacyPage'
import Spinner from '../components/Spinner'
import UploadData from '../components/UploadData'
import Button from '../components/core/Button/Button'
import {
  bulkDelete,
  getBuildings,
  uploadBuildingsFile,
} from '../loaders/buildings'

export default function Buildings() {
  const gridRef = useRef(null)
  const { t } = useTranslation(['dashboard'])

  const { data, isLoading, refetch } = useQuery(['buildings'], getBuildings)
  const uploadMutation = useMutation({
    mutationFn: uploadBuildingsFile,
    onSuccess: () => {
      return refetch().then(() => {
        gridRef.current.api.refreshCells()
      })
    },
  })

  const mutation = useMutation({
    mutationFn: bulkDelete,
    onSuccess: () => {
      return refetch().then(() => {
        gridRef.current.api.refreshCells()
      })
    },
  })

  const onUpload = useCallback((event) => {
    const files = Array.from(event.target.files)

    uploadMutation.mutate(files[0])
  })

  const onDeleteSelection = useCallback(() => {
    const selection = gridRef.current.api.getSelectedRows()

    mutation.mutate(selection.map((item) => item.buildingId))
  }, [])

  return (
    <Checked right="building:write">
      <Page.Header
        actions={
          <>
            <Button variant="negative" onClick={onDeleteSelection}>
              {t('buildings.deleteSelection')}
            </Button>
            <Button as={Link} to="/setup/buildings/new" className="ml-2">
              {t('buildings.createBuilding')}
            </Button>
            <UploadData onUpload={onUpload} />
          </>
        }
      >
        {t('buildings.title')}
      </Page.Header>
      <Page.Section id="grid">
        {isLoading ? (
          <div className="flex justify-center">
            <Spinner />
          </div>
        ) : (
          <Grid rowData={data} ref={gridRef} />
        )}
      </Page.Section>
    </Checked>
  )
}
